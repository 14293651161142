import { css } from '@emotion/react'
import React, { ComponentType } from 'react'
import { OpStateHookRes, useOpState } from '../ui/useOpState'
import { useForm } from 'react-hook-form'
import { Button, Field } from '@fluentui/react-components'
import prettyBytes from 'pretty-bytes'
import { ErrorMsg } from '../ui/ErrorMsg'
import { InfoMsg } from '../ui/InfoMsg'
import { Spinner } from '../ui/Spinner'
import { useApi } from '../api/useApi'
import { FormEnumRadioGroup } from '../ui/form/FormRadioGroup'
import { PdfExtractorType } from '@apis/my'

export const PdfExtractor = () => {
    const op = useOpState<string[]>()

    return (
        <div>
            <h1
                css={css`
                    text-align: center;
                    margin-bottom: 0em;
                `}
            >
                PDF extractor
            </h1>
            <div
                css={css`
                    width: 100%;
                    display: flex;
                `}
            >
                <div
                    css={css`
                        width: 30em;
                    `}
                >
                    <h2>Upload PDF file</h2>
                    <InputForm op={op}
                    />
                </div>
                <div>
                    <h2>Result</h2>
                    {op.data?.map((d, i) => (
                        <div key={i}>
                            <h4>Chunk {i + 1}</h4>
                            <pre>{d}</pre>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

const InputForm: ComponentType<{
    op: OpStateHookRes<string[]>,
}> = ({
          op,
      }) => {
    const form = useForm<{
        files: FileList
        implementation: PdfExtractorType
    }>({
        defaultValues: {
            // TODO
            files: [] as any,
            implementation: PdfExtractorType.JAVA_HELPER_SERVICE,
        },
    })

    const {
        register,
        handleSubmit,
        watch,
    } = form

    const files = Array.from(watch('files') ?? [])

    const api = useApi()

    // TODO drive by backend
    let accepts = ['application/pdf']
    if (form.getValues('implementation') === PdfExtractorType.AZURE) {
        accepts = ['application/pdf', 'image/png']
    }

    return (
        <div>
            {!op.pending && (
                <form
                    css={css`
                        //display: flex;
                        //flex-direction: column;
                    `}
                    onSubmit={handleSubmit(async (data) => {
                        await op.handle(
                            api.pdf.extract({
                                file: data.files[0],
                                implementation: data.implementation,
                            }),
                        )
                        // form.reset()
                        // setValue('files', null as any)
                    })}
                >
                    <FormEnumRadioGroup
                        enumObj={PdfExtractorType}
                        form={form}
                        field={'implementation'}
                        label={'Implementation'}
                    />
                    <Field label={'Files to upload'}>
                        <input
                            css={css`
                                height: 3em;
                                border: 1px solid gray;
                                cursor: pointer;
                            `}
                            id={'files'}
                            multiple={false}
                            accept={accepts.join(' ')}
                            type="file"
                            {...register('files')}
                        />
                        <ul>
                            {files.map((f, i) => (
                                <li key={i}>
                                    {f.name} {prettyBytes(f.size)}
                                </li>
                            ))}
                        </ul>
                    </Field>
                    <Button type="submit">Submit</Button>
                    <ErrorMsg error={op.error} />
                    <InfoMsg shown={op.success}>Files uploaded</InfoMsg>
                </form>
            )}
            <Spinner shown={op.pending}>Uploading...</Spinner>
        </div>
    )
}
