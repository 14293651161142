import {observable} from 'mobx'
import {FrontendConf, MyApi,} from '@apis/my'

export type AppSettings = {
    flags: FrontendConf['flags']
    name: string
    version: string
    branding: FrontendConf['branding']
}

export class AppStore {

    @observable accessor settings: AppSettings


    constructor(private api: MyApi, private conf: FrontendConf) {
        this.settings = {
            version: conf.version,
            name: conf.title,
            flags: conf.flags,
            branding: conf.branding
        }
    }

    //
    // loadSettings = async (): Promise<{
    //     favicon: {
    //         href: string
    //         type: string
    //     },
    //     title: string
    //     appVersion: string
    // }> => {
    //     // const [info, settings]: [AppInfo, ApiAppSettings] = await Promise.all([
    //     //     this.api.app.getInfo() as any,
    //     //     this.api.app.getAppSettings()
    //     // ])
    //
    //     // const theme = getTheme(settings.ui.theme)
    //
    //     return {
    //         favicon: {
    //             href: theme.favicon,
    //             type:
    //                 'image/' + theme.favicon.endsWith('png') ? 'png' : 'x-icon',
    //         },
    //         title: settings.displayName + ' chatbot',
    //         appVersion: info.version
    //     }

// }
}
