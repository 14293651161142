import { MyApi } from './api'
import { useAppContext } from '../ctx/MyContext'
import { useQuery } from 'react-query'
import { UseQueryResult } from 'react-query/types/react/types'

export const useApi = (): MyApi => useAppContext().api

let id = 1

export const useApiData = <D>(get: (api: MyApi) => D): UseQueryResult<D> => {
    const api = useApi()
    return useQuery(`query-${id++}`, () => get(api))
}
