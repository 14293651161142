import { ComponentType } from 'react'
import { css } from '@emotion/react'

export const ErrorMsg: ComponentType<{
    children?: string
    shown?: boolean
    error?: Error
}> = ({ children, shown, error }) =>
    error || shown ? (
        <span
            css={css`
                color: red;
            `}
        >
            {children || `Failed: ${error ? `${error.message}` : ''}`}
        </span>
    ) : null
