import React, {useContext} from 'react'
import Axios from 'axios'
import {FrontendConf, MyApi} from '@apis/my'
import {createHttpClient} from '@ps-aux/api-client-axios'
import {SearchConsoleStore} from '../playground/SearchConsoleStore'
import {SearchCollectionStore} from '../chat/SearchCollectionsStore'
import {ChatStore} from '../chat/ChatStore'
import {UiControls} from './UiControls'
import {AuthStore} from '../auth/Auth.store'
import {AppStore} from "../app/App.store";

export type MyContext = {
    api: MyApi
    app: {
        store: AppStore
    },
    auth: {
        store: AuthStore
    }
    collection: {
        store: SearchCollectionStore
    }
    search: {
        consoleStore: SearchConsoleStore
    }
    chat: {
        store: ChatStore
    }
    ui: UiControls
}

export const createAppContext = (conf:FrontendConf): MyContext => {

    const ui = new UiControls()
    const axios = Axios.create()
    const api = new MyApi(createHttpClient(axios))

    const authStore = new AuthStore(api, ui, conf.publicAccess, window.localStorage)

    axios.interceptors.response.use(
        (r) => r,
        (error) => {
            // TODO refine more
            if (error.response.status === 401) {
                console.error('Got 401. Removing user session')
                authStore.currentUser = null
                ui.showToast('Session no longer valid')
            }

            const msg = error.response?.data?.message
            if (msg) error = new Error(msg)
            return Promise.reject(error)
        },
    )

    const collectionStore = new SearchCollectionStore(api)
    return {
        api,
        app: {
            store: new AppStore(api, conf)
        },
        chat: {
            store: new ChatStore(api, collectionStore),
        },
        collection: {
            store: collectionStore,
        },
        search: {
            consoleStore: new SearchConsoleStore(api, collectionStore),
        },
        auth: {
            store: authStore,
        },
        ui,
    }
}

const MyContext_ = React.createContext<MyContext>(null as any)

export const MyContextProvider = MyContext_.Provider

export const useAppContext = (): MyContext => {
    return useContext(MyContext_)
}
