import React, {ComponentType, useEffect, useState} from 'react'
import {useAppContext} from '../ctx/MyContext'
import {observer} from 'mobx-react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {LOGIN_FAILED_QUERY_PARAM, LOGIN_SUCCESS_QUERY_PARAM} from './Auth.store'
import {css} from "@emotion/react";
import {Button} from "@fluentui/react-components";

export const AuthProvider: ComponentType<React.PropsWithChildren> = observer(
    ({children}) => {
        const {auth, app} = useAppContext()
        const store = auth.store

        const [searchParams, setSearchParams] = useSearchParams()
        const nav = useNavigate()

        const [error, setError] = useState<string | undefined>()

        const loginSuccess =
            searchParams.get(LOGIN_SUCCESS_QUERY_PARAM) === 'true'

        const loginFailed =
            searchParams.get(LOGIN_FAILED_QUERY_PARAM) === 'true'

        useEffect(() => {

            // Refresh the current user
            if (store.hasSession) {
                store.loadCurrentUser()
            }


            // No callback handling
            if (!loginFailed && !loginSuccess)
                return

            if (loginSuccess) {
                store.loadCurrentUser()
            } else if (loginFailed) {
                setError(searchParams.get('error_msg')!)
            }

            // Remove the query param from the URL
            searchParams.delete(LOGIN_SUCCESS_QUERY_PARAM)
            searchParams.delete(LOGIN_FAILED_QUERY_PARAM)
            nav({
                search: searchParams.toString(),
            })

        }, [])

        // Wait until hydration
        if (!store.isHydrated)
            return null

        if (!store.currentUser && store.fetchingUser)
            return null

        // After logyout avoid displaying the login form (this does happen)
        if (!store.currentUser && !store.publicAccessAllowed)
            return (
                <div css={css`
                    margin: auto;
                    width: 30em;
                    margin-top: 20em;
                `
                }>
                    <h2>Authentication required. Please log in.</h2>
                    <Button
                        appearance={'primary'}
                        onClick={() => {
                            const cb = `/`
                            store.initiateLogin(cb)
                        }}
                    >
                        Login
                    </Button>
                    <div css={css`
                        color: red;
                    `}>{error}</div>
                </div>
            )

        return <div>{children}</div>
    },
)
