import { ComponentType } from 'react'
import { Spinner as FluentUiSpinner } from '@fluentui/react-components'

export const Spinner: ComponentType<{ children?: string; shown: boolean }> = ({
    children,
    shown,
}) =>
    shown ? (
        <FluentUiSpinner labelPosition="below" label={children} />
    ) : null
