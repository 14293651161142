import { css } from '@emotion/react'
import { useCollectionStore } from '../chat/SearchCollectionsStore'
import { Button } from '../ui/Button'
import { AppGeneric24Regular, Settings24Regular } from '@fluentui/react-icons'
import React, { ComponentType, useState } from 'react'
import { ChatbotSettingsComp } from '../chat/ChatbotSettingsComp'
import { useNavigate } from 'react-router-dom'
import { CollectionSelector } from '../playground/CollectionSelector'
import {useAppContext} from "../ctx/MyContext";
import {observer} from "mobx-react";


const Label: ComponentType<{ children: React.ReactNode }> = ({ children }) =>
    <label css={css`
        font-weight: bold;
        margin-right: 1em;
    `}>
        {children}
    </label>


export const DeveloperPanel = observer(() => {
    const col = useCollectionStore()

    const [chatbotSettingsShown, setChatbotSettingsShown] = useState(false)

    const nav = useNavigate()

    const {auth} = useAppContext()

    return <div css={css`
        display: flex;
        background: white;
        padding: 1em;
        align-items: center;
    `}>
        <Label>Knowledge base</Label>
        <CollectionSelector horizontal />
        <div css={css`
            margin-left: auto;
            display: flex;
        `}>
            <Button
                icon={Settings24Regular}
                onClick={() => setChatbotSettingsShown(!chatbotSettingsShown)}
                css={css`
                    background: none;
                `}
            >
                Chatbot settings
            </Button>
            {auth.store.isAdmin && <Button
                css={css`
                    background: none;
                `}
                icon={AppGeneric24Regular}
                onClick={() => nav('/admin')}
            >
                Admin
            </Button>}
        </div>
        <ChatbotSettingsComp
            shown={chatbotSettingsShown}
            onClose={() => setChatbotSettingsShown(false)}
        />
    </div>
})
