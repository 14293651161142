// TODO refactor later
import React, {useEffect} from 'react'
import {BrowserRouter, Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import Layout from './pages/layout/Layout'
import {ChatbotScreen} from './chat/ChatbotScreen'
import NoPage from './pages/NoPage'
import {Admin} from './playground/Admin'
import {AuthProvider} from './auth/AuthProvider'
import {useAppContext} from './ctx/MyContext'

const RootLayout = () => {
    const navigate = useNavigate()
    const ctx = useAppContext()

    useEffect(() => {
        ctx.ui.register.navigate(navigate)
    }, [])


    return (
        <Layout>
            <AuthProvider>
                <Outlet/>
            </AuthProvider>
        </Layout>
    )
}

const PrivateZoneLayout = () => {
    const ctx = useAppContext()

    useEffect(() => {
        ctx.collection.store.fetch()
        ctx.api.chat.getSettings().then(
            (s) => {
                ctx.chat.store.settings = s
            },
        )
    }, [])

    return <Outlet/>
}

export default function Routing() {
    // {initEl && <Route path="/" element={props.initEl} />}
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<RootLayout/>}>
                    <Route path={'/'} element={<PrivateZoneLayout/>}>
                        <Route index element={<ChatbotScreen/>}/>
                        <Route path="*" element={<NoPage/>}/>
                    </Route>
                    <Route path="/admin/*" element={<Admin/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
