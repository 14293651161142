import { AskResponse, ChatbotSettings, ChatMessage as ApiChatMessage, MyApi } from '@apis/my'
import { SearchCollectionStore } from './SearchCollectionsStore'
import { computed, observable } from 'mobx'
import { useAppContext } from '../ctx/MyContext'

export type ChatMsg =
    | {
    role: 'user'
    content: string
}
    | {
    role: 'assistant'
    content: string
    res: AskResponse
}

export class ChatStore {
    constructor(
        private api: MyApi,
        private collections: SearchCollectionStore,
    ) {
        // makeObservable(this)
    }

    @computed get enabled() {
        return this.collections.selected
    }

    @observable accessor msgs: ChatMsg[] = []

    hasStarted = (): boolean => this.msgs.length > 0

    @computed get canEnterMsg(): boolean {
        // No conversation for now just and answer
        return this.msgs.length < 1
    }

    // @computed get lastMsg(): ChatMsg {
    //     return this.msgs[this.msgs.length - 1]
    // }

    @observable accessor settings: ChatbotSettings | undefined = undefined

    send = async (messages: ApiChatMessage[]) => {
        if (!this.collections.selected)
            throw new Error(`No collection selected`)
        return this.api.chat.msg({
            messages,
            collectionId: this.collections.selected!.id,
        })
    }

    submitMsg = async (msg: string): Promise<void> => {
        this.msgs.push({
            role: 'user',
            content: msg,
        })
        const res = await this.send(this.msgs.map(m => ({
            role: m.role,
            content: m.content
        })))

        this.msgs.push({
            role: 'assistant',
            content: res.answer,
            res: res,
        })
    }

    askAgain = async (msgIndex: number): Promise<void> => {
        const msg = this.msgs[msgIndex]
        if (msg.role !== 'user')
            throw new Error(`The ${msgIndex} msg is not a user message`)

        this.msgs = this.msgs.filter((_, i) => i < msgIndex)
        return this.submitMsg(msg.content)
    }

    clearChat = () => {
        this.msgs = []
    }
}

export const useChatStore = (): ChatStore => {
    return useAppContext().chat.store
}
