import {Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle} from '@fluentui/react-drawer'
import React, {ComponentType, useEffect} from 'react'
import {Button, Field, Input, Textarea} from '@fluentui/react-components'
import {Add16Regular, Delete16Regular, Dismiss24Regular} from '@fluentui/react-icons'
import {css} from '@emotion/react'
import {observer} from 'mobx-react'
import {useChatStore} from './ChatStore'
import {Controller, useForm} from 'react-hook-form'
import {ChatbotSettings} from '@apis/my'
import {useApi} from '../api/useApi'
import {FormSubmit} from '../ui/FormSubmit'
import {useOpState} from '../ui/useOpState'


export const ChatbotSettingsComp: ComponentType<{
    shown: boolean
    onClose: () => void
}> = observer(({shown, onClose}) => {

    const chat = useChatStore()
    const api = useApi()

    // TODO what if this changes
    const form = useForm<ChatbotSettings>()

    useEffect(() => {
            form.reset(chat.settings)
        },
        // Reset when settings loaded - TODO, quick hack, implement properly
        [chat.settings?.name])


    const save = useOpState()

    return (
        <Drawer
            // type={shown}
            separator
            css={css`
                width: 50em;
            `}
            position={'end'}
            open={shown}
            onOpenChange={(_, {open}) => {
                if (!open) onClose()
            }}
        >
            <DrawerHeader>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular/>}
                            onClick={() => onClose()}
                        />
                    }
                >
                    Chatbot settings
                </DrawerHeaderTitle>
            </DrawerHeader>

            <DrawerBody

            >
                <form
                    css={css`
                        > * {
                            margin-bottom: 2em;
                        }
                    `}
                    onSubmit={form.handleSubmit(async data => {
                        data = {
                            ...data,
                            answerPrompt: data.answerPrompt.trim(),
                            generateQueryPrompt: data.generateQueryPrompt.trim(),
                        }
                        await save.handle(
                            api.chat.saveSettings(data),
                        )
                        chat.settings = data
                        onClose()
                    })}>
                    <Field label="Chat description">
                        <Input
                            defaultValue={form.getValues('chatDescription')}
                            {...form.register('chatDescription', {
                                required: true,
                            })}
                        />
                    </Field>
                    <Field label="Query generation prompt">
                        <Textarea
                            resize={'vertical'}
                            {...form.register('generateQueryPrompt', {
                                required: true,
                            })}
                            rows={4}
                        />
                    </Field>
                    <Field label="Answer prompt">
                        <Textarea
                            resize={'vertical'}
                            {...form.register('answerPrompt', {
                                required: true,
                            })}
                            rows={5}
                        />
                    </Field>
                    <Field label="Document search limit">
                        <Input
                            type={'number'}
                            defaultValue={form.getValues('searchDocLimit') + ''}
                            {...form.register('searchDocLimit', {
                                valueAsNumber: true,
                                required: true,
                            })}
                        />
                    </Field>
                    <Controller
                        name="questions"
                        control={form.control}
                        render={({field}) => {
                            const vals: string[] = field.value
                            return <div css={css`
                                display: flex;
                                flex-direction: column;

                                > * {
                                    margin-bottom: 0.5em;
                                }
                            `}>
                                {vals.map((item, index) => (
                                    <div key={index}>
                                        <Input
                                            value={item}
                                            css={css`
                                                width: 25em;
                                            `}
                                            onChange={e => {
                                                const newArray = [...field.value]
                                                newArray[index] = e.target.value
                                                field.onChange(newArray)
                                            }}
                                        />
                                        <Button
                                            icon={<Delete16Regular/>}
                                            css={css`
                                                margin-left: 1em;
                                            `}
                                            onClick={() => {
                                                field.onChange(vals.filter((_, i) => i !== index))
                                            }}></Button>
                                    </div>
                                ))}
                                <Button
                                    type="button"
                                    icon={<Add16Regular/>}
                                    css={css`
                                        width: 5em;
                                    `}
                                    onClick={() => {
                                        const newArray = [...field.value, ''] // Add an empty string to array
                                        field.onChange(newArray)
                                    }}
                                >
                                </Button>
                            </div>
                        }}
                    />
                    <FormSubmit op={save}/>
                </form>
            </DrawerBody>
        </Drawer>
    )
})
