import {Link} from 'react-router-dom'
import {css} from '@emotion/react'
import styles from './Layout.module.css'

import {useAppContext} from '../../ctx/MyContext'
import {observer} from "mobx-react";
import React, {ComponentType} from "react";


const TopbarButton: ComponentType<{ children: React.ReactNode, onClick?: () => void }> = ({children, onClick}) => {
    return <button css={css`
        border: none;
        background: none;
        color: inherit;
        cursor: pointer;
        font-size: 1.2em;
        font-weight: bold;
    `} onClick={onClick}>
        {children}
    </button>
}

const MyHeaderContent = observer(() => {
    const ctx = useAppContext()
    const {
        branding,
        name: appName,
    } = ctx.app.store.settings

    const auth = ctx.auth.store

    const theme = branding.theme

    const background = theme.header.background
    const textColor = theme.header.textColor

    return (
        <div
            css={css`
                height: ${theme.header.height || '4.2em'};
                //height: auto;
                background: ${background};
                display: flex;
                justify-content: center;
                position: relative;
                color: ${textColor};
            `}
        >
            <div
                css={css`
                    //background: yellow;
                    min-width: 70em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: ${textColor};
                    text-decoration: none;
                    position: relative;
                `}
            >
                <Link
                    to={'/'}
                    css={css`
                        left: 0;
                        position: absolute;
                        padding: 0em;
                    `}
                >
                    <img
                        src={branding.logoUrl}
                        height={theme.header.logoHeight}
                        css={css`
                            //max-height: 100%;
                        `}
                    />
                </Link>
                <h1 css={css`
                    font-size: ${theme.header.titleFontSize || 'auto'};
                `}>{appName}</h1>
                {/*<>*/}
                {/*    <Link*/}
                {/*        to="/"*/}
                {/*        style={{*/}
                {/*            color: textColor,*/}
                {/*            textDecoration: 'none',*/}
                {/*            marginRight: '4em',*/}
                {/*        }}*/}
                {/*    ></Link>*/}
                {/*    /!*<span>{version}</span>*!/*/}
                {/*</>*/}
            </div>
            <div
                css={css`
                    position: absolute;
                    right: 0;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding-right: 1em;
                `}
            >
                {auth.currentUser?.name}
                {auth.currentUser?.admin && ' (admin) '}
                {auth.hasSession && <TopbarButton
                    onClick={() => {
                        // ctx.ui.showToast('Test')
                        ctx.auth.store.logout()
                    }}
                >
                    Log out
                </TopbarButton>}
                {!auth.hasSession && <TopbarButton onClick={() => {
                    auth.initiateLogin('/')
                }}>Login</TopbarButton>}
            </div>
        </div>
    )
})

const Layout: ComponentType<{ children: React.ReactNode }> = ({children}) => {
    return (
        <div className={styles.layout}>
            <header role={'banner'}>
                <MyHeaderContent/>
            </header>
            {children}
        </div>
    )
}

export default Layout
