/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AppInfo {
    version: string
}

export enum PdfExtractorType {
    JAVA_HELPER_SERVICE = 'JAVA_HELPER_SERVICE',
    AZURE = 'AZURE',
}

export interface PossibleDataSettingsDto {
    pdfExtractors: PdfExtractorType[]
}

export interface AppSettings {
    displayName: string
    id: string
    flags: {
        sourceNamesFixer: boolean
    }
    possible: PossibleDataSettingsDto
}

export type DataSettings = object

export interface UiThemeHeader {
    height?: string
    background: string
    textColor: string
    logoHeight: number
    titleFontSize?: string
}

export interface UiTheme {
    color: string
    borderRadius: string
    boxShadow: string
    header: UiThemeHeader
    outlineSelectionColor?: string
    hideCollectionPicker?: boolean
}

export interface FrontendConf {
    title: string
    version: string
    publicAccess: boolean
    flags: {
        sourceNamesFixer: boolean
    }
    branding: {
        logoUrl: string
        faviconUrl: string
        theme: UiTheme
    }
}

export interface LoginInitiateRes {
    redirect?: string
    done: boolean
}

export interface CurrentUser {
    name: string
    admin: boolean
}

export enum SearchBackendType {
    VECTOR_OPEN_AI_PG = 'VECTOR_OPEN_AI_PG',
    AZURE_AI_SEARCH = 'AZURE_AI_SEARCH',
}

export interface CollectionUpdateCmd {
    searchBackend: SearchBackendType
    pdfExtractor: PdfExtractorType
}

export interface CollectionCreateCmd {
    name: string
}

export interface CollectionId {
    id: number
}

export interface SourceDocument {
    collection: CollectionId
    id: number
    name: string
    type: string
    sizeInBytes: number
    entitiesCount: number
}

export interface DoSearchResultItem {
    id: number
    documentId: number
    name: string
    content: string
}

export interface DoSearchRes {
    results: DoSearchResultItem[]
}

export interface FilesPayload {
    files: File[]
}

export interface SemanticTextEntity {
    id: number
    name: string
    content: string
}

export interface Collection {
    searchBackend: SearchBackendType
    pdfExtractor: PdfExtractorType
    id: number
    name: string
}

export interface SearchCollectionInfo {
    semanticEntitiesCount: number
    name: string
    documentsCount: number
}

export interface ExtractPdfReq {
    /** @format binary */
    file: File
    implementation: 'JAVA_HELPER_SERVICE' | 'AZURE'
}

export interface ChatMessage {
    role: string
    content: string
}

export interface ChatRequest {
    messages: ChatMessage[]
    collectionId: number
}

export interface ChatbotThoughtProcessExplanation {
    queryPrompt: string
    query: string
    answerPrompt?: ChatMessage[]
    answer?: string
}

export interface DataPoint {
    name: string
    content: string
}

export interface AskResponse {
    answer: string
    thoughts: ChatbotThoughtProcessExplanation
    dataPoints: DataPoint[]
    error?: string
}

export interface ChatbotSettings {
    name: string
    chatDescription: string
    generateQueryPrompt: string
    answerPrompt: string
    questions: string[]
    searchDocLimit: number
}

export type ContentType = {}

export type RequestParams = {
    fileDownload?: boolean
}

export const ContentType = {
    Json: 'application/json',
    FormData: 'multipart/form-data',
}

export type Request = {
    path: string
    method: 'GET' | 'POST' | 'PUT' | 'DELETE'
    format?: 'json' | 'document'
    query?: any
    body?: any
    type?: string
    secure?: boolean
}

export type HttpClient = {
    request: <Data, A = any>(req: Request) => Promise<Data>
}

/**
 * @title ai-proto api
 * @version 1.0.0
 * @contact
 */
export class MyApi {
    http: HttpClient

    constructor(http: HttpClient) {
        this.http = http
    }

    app = {
        /**
         * No description
         *
         * @tags app
         * @name GetInfo
         * @request GET:/api/info
         */
        getInfo: (params: RequestParams = {}) =>
            this.http.request<AppInfo, any>({
                path: `/api/info`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags app
         * @name GetAppSettings
         * @request GET:/api/app/settings
         */
        getAppSettings: (params: RequestParams = {}) =>
            this.http.request<AppSettings, any>({
                path: `/api/app/settings`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags app
         * @name GetDataSettings
         * @request GET:/api/app/data-settings
         */
        getDataSettings: (params: RequestParams = {}) =>
            this.http.request<DataSettings, any>({
                path: `/api/app/data-settings`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags app
         * @name SaveDataSettings
         * @request PUT:/api/app/data-setting
         */
        saveDataSettings: (data: DataSettings, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/app/data-setting`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags app
         * @name GetConfig
         * @request GET:/api/app/config
         */
        getConfig: (params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/app/config`,
                method: 'GET',
                ...params,
            }),
    }
    spa = {
        /**
         * No description
         *
         * @tags spa
         * @name GetSpaConf
         * @request GET:/api/spa/conf
         */
        getSpaConf: (params: RequestParams = {}) =>
            this.http.request<FrontendConf, any>({
                path: `/api/spa/conf`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    }
    auth = {
        /**
         * No description
         *
         * @tags auth
         * @name InitiateLogin
         * @request GET:/api/auth/login-initiate
         */
        initiateLogin: (
            query: {
                cb: string
            },
            params: RequestParams = {},
        ) =>
            this.http.request<LoginInitiateRes, any>({
                path: `/api/auth/login-initiate`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags auth
         * @name Login
         * @request GET:/api/auth/login
         */
        login: (params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/auth/login`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags auth
         * @name LoginCb
         * @request GET:/api/auth/login/cb
         */
        loginCb: (params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/auth/login/cb`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags auth
         * @name Logout
         * @request POST:/api/auth/logout
         */
        logout: (params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/auth/logout`,
                method: 'POST',
                ...params,
            }),
    }
    user = {
        /**
         * No description
         *
         * @tags user
         * @name UserInfo
         * @request GET:/api/user/info
         */
        userInfo: (params: RequestParams = {}) =>
            this.http.request<CurrentUser, any>({
                path: `/api/user/info`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    }
    search = {
        /**
         * No description
         *
         * @tags search
         * @name UpdateCollection
         * @request PUT:/api/search/collection/{collectionId}
         */
        updateCollection: (collectionId: number, data: CollectionUpdateCmd, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/search/collection/${collectionId}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name DeleteCollection
         * @request DELETE:/api/search/collection/{collectionId}
         */
        deleteCollection: (collectionId: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/search/collection/${collectionId}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name GetCollectionInfo
         * @request GET:/api/search/collection/{collectionId}
         */
        getCollectionInfo: (collectionId: number, params: RequestParams = {}) =>
            this.http.request<SearchCollectionInfo, any>({
                path: `/api/search/collection/${collectionId}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name CreateCollection
         * @request POST:/api/search/collection
         */
        createCollection: (data: CollectionCreateCmd, params: RequestParams = {}) =>
            this.http.request<number, any>({
                path: `/api/search/collection`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name GetCollections
         * @request GET:/api/search/collection
         */
        getCollections: (params: RequestParams = {}) =>
            this.http.request<Collection[], any>({
                path: `/api/search/collection`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name GetCollectionSourceDocs
         * @request GET:/api/search/collection/{collectionId}/source-docs
         */
        getCollectionSourceDocs: (collectionId: number, params: RequestParams = {}) =>
            this.http.request<SourceDocument[], any>({
                path: `/api/search/collection/${collectionId}/source-docs`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name Query
         * @request GET:/api/search/query/{collectionId}
         */
        query: (
            collectionId: number,
            query: {
                q: string
            },
            params: RequestParams = {},
        ) =>
            this.http.request<DoSearchRes, any>({
                path: `/api/search/query/${collectionId}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name SetFromPdf
         * @request POST:/api/search/upload/{collectionId}
         */
        setFromPdf: (collectionId: number, data: FilesPayload, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/search/upload/${collectionId}`,
                method: 'POST',
                body: data,
                type: ContentType.FormData,
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name GetDocumentTextEntities
         * @request GET:/api/search/document/{id}/text-entities
         */
        getDocumentTextEntities: (id: number, params: RequestParams = {}) =>
            this.http.request<SemanticTextEntity[], any>({
                path: `/api/search/document/${id}/text-entities`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name GetDocumentContent
         * @request GET:/api/search/document/{id}
         */
        getDocumentContent: (id: number, params: RequestParams = {}) =>
            this.http.request<File, any>({
                path: `/api/search/document/${id}`,
                method: 'GET',
                format: 'document',
                ...params,
            }),
    }
    pdf = {
        /**
         * No description
         *
         * @tags pdf
         * @name Extract
         * @request PUT:/api/pdf/extract
         */
        extract: (data: ExtractPdfReq, params: RequestParams = {}) =>
            this.http.request<string[], any>({
                path: `/api/pdf/extract`,
                method: 'PUT',
                body: data,
                type: ContentType.FormData,
                format: 'json',
                ...params,
            }),
    }
    vector = {
        /**
         * No description
         *
         * @tags vector
         * @name Embed
         * @request GET:/api/vector/embed
         */
        embed: (
            query: {
                text: string
            },
            params: RequestParams = {},
        ) =>
            this.http.request<void, any>({
                path: `/api/vector/embed`,
                method: 'GET',
                query: query,
                ...params,
            }),
    }
    metrics = {
        /**
         * No description
         *
         * @name Index
         * @request GET:/metrics
         */
        index: (params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/metrics`,
                method: 'GET',
                ...params,
            }),
    }
    llm = {
        /**
         * No description
         *
         * @tags llm
         * @name Save
         * @request GET:/api/llm/save
         */
        save: (params: RequestParams = {}) =>
            this.http.request<DataSettings, any>({
                path: `/api/llm/save`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    }
    chat = {
        /**
         * No description
         *
         * @tags chat
         * @name Msg
         * @request POST:/api/chat/msg
         */
        msg: (data: ChatRequest, params: RequestParams = {}) =>
            this.http.request<AskResponse, any>({
                path: `/api/chat/msg`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags chat
         * @name GetSettings
         * @request GET:/api/chat/settings
         */
        getSettings: (params: RequestParams = {}) =>
            this.http.request<ChatbotSettings, any>({
                path: `/api/chat/settings`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags chat
         * @name SaveSettings
         * @request PUT:/api/chat/settings
         */
        saveSettings: (data: ChatbotSettings, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/chat/settings`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                ...params,
            }),
    }
}
