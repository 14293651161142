import { useAppContext } from '../ctx/MyContext'
import { css } from '@emotion/react'
import { ComponentType } from 'react'
import { useForm } from 'react-hook-form'
import { Spinner } from '../ui/Spinner'
import { ErrorMsg } from '../ui/ErrorMsg'
import { DoSearchRes } from '@apis/my'
import { useOpState } from '../ui/useOpState'
import { useCollectionStore } from '../chat/SearchCollectionsStore'

const SearchResult: ComponentType<{ value: DoSearchRes }> = ({
    value: { results },
}) => (
    <div>
        {results.length === 0 && <span>No matches</span>}
        {results.map((r) => (
            <div
                key={r.id}
                css={css`
                    margin-bottom: 2em;
                    border-bottom: 1px solid gray;
                `}
            >
                <div
                    css={css`
                        font-weight: bold;
                    `}
                >
                    {r.name}
                </div>
                <pre
                    css={css`
                        font-size: 0.9em;
                    `}
                >
                    {r.content}
                </pre>
            </div>
        ))}
    </div>
)

export const QueryPanel: ComponentType<{}> = () => {
    const { api } = useAppContext()

    const search = useOpState<DoSearchRes>()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<{ query: string }>()

    const s = useCollectionStore()

    return (
        <div>
            <div css={css``}>
                <h2>Query</h2>
                {s.selected && (
                    <form
                        onSubmit={handleSubmit((data) => {
                            search.handle(
                                api.search.query(s.selected!.id, {
                                    q: data.query,
                                }),
                            )
                        })}
                    >
                        <input {...register('query')} />
                        <button disabled={false}> Submit</button>
                    </form>
                )}
            </div>
            <div
                css={css`
                    max-width: 200em;
                `}
            >
                {!search.pending && search.data && (
                    <>
                        <h2>Results</h2>
                        <div
                            css={css`
                                display: flex;

                                > * {
                                    flex: 1;
                                }
                            `}
                        >
                            <SearchResult value={search.data!} />
                        </div>
                    </>
                )}
                <Spinner shown={search.pending}>Searching...</Spinner>
                <ErrorMsg error={search.error} />
            </div>
        </div>
    )
}
