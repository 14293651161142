import { SearchConsole } from './SearchConsole'
import { Link, NavLink, Navigate, Route, Routes } from 'react-router-dom'
import React from 'react'
import { css, useTheme } from '@emotion/react'
import { PdfExtractor } from './PdfExtractor'
import { Settings } from './Settings'
import { CollectionAdmin } from './CollectionAdmin'
import { CollectionSelector } from './CollectionSelector'
import {useAppContext} from "../ctx/MyContext";

export const paths = {
    searchConsole: '/playground-search-console',
}

const menuItems = [
    {
        label: 'Collection',
        path: './collection',
    },
    {
        label: 'Search console',
        path: './search-console',
    },
    {
        label: 'PDF content extractor',
        path: './pdf-extractor',
    },
    // {
    //     label: 'Settings',
    //     path: './settings',
    // },
]

export const Admin = () => {
    const t = useTheme()
    const app = useAppContext().app.store
    return (
        <div
            css={css`
                display: flex;
                height: 100%;
            `}
        >
            <div css={css`
                width: 12em;
                padding: 1em;
                background: white;
            `}>
                <Link
                    to={'/'}
                    css={css`
                        font-weight: bold;
                        text-decoration: none;
                        color: black;
                    `}
                >
                    Back to chatbot
                </Link>
                <div
                    css={css`
                        margin-bottom: 2em;
                    `}
                />
                <CollectionSelector />
            </div>
            <div
                css={css`
                    width: 100%;
                `}
            >
                <div css={css`
                    padding: 1em;
                    display: flex;
                    background: white;
                `}>
                    {menuItems.map((i) => (
                        <NavLink
                            key={i.path}
                            to={i.path}
                            style={({ isActive }) => ({
                                color: isActive ? t.color : 'black',
                            })}
                            css={(t) => css`
                            margin-right: 1em;
                            font-weight: bold;
                            font-size: 1.2em;
                            text-decoration: none;
                            color: black;
                        `}
                        >
                            {i.label}
                        </NavLink>
                    ))}
                    <div css={css`
                        margin-left: auto;
                    `}>
                        Version: {app.settings.version}
                    </div>
                </div>
                <div css={css`
                    padding: 2em;
                `}>
                    <Routes>
                        <Route path="/collection" element={<CollectionAdmin />} />
                        <Route path="/pdf-extractor" element={<PdfExtractor />} />
                        <Route path="/search-console" element={<SearchConsole />} />
                        {/*<Route path="/settings" element={<Settings />} />*/}
                        <Route
                            path="*"
                            element={<Navigate to="./collection" replace />}
                        />
                    </Routes>
                </div>
            </div>
        </div>
    )
}
