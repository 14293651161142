import React, { ComponentType } from 'react'
import { Radio, RadioGroup as FuiRadioGroup } from '@fluentui/react-components'

export type Option = {
    value: string
    label: string
}

export const simpleOptions = (vals: string[]): Option[] =>
    vals.map((value) => ({ value, label: value }))

export const RadioGroup: ComponentType<{
    options: Option[]
    value?: string
    horizontal?: boolean
    onChange: (val: string) => void
}> = ({ options, value, onChange, horizontal }) => (
    <FuiRadioGroup
        value={value}
        layout={horizontal ? 'horizontal' : undefined}
        onChange={(_, v) => onChange(v.value)}
    >
        {options.map((o) => (
            <Radio key={o.value} value={o.value} label={o.label} />
        ))}
    </FuiRadioGroup>
)
