// import { Chat } from './chat/Chat'
import {Chat} from './chat/Chat2'
import {css} from '@emotion/react'
import {DeveloperPanel} from '../pages/DeveloperPanel'
import {observer} from "mobx-react";
import {useAppTheme} from "../branding/useTheme";
import {useAppContext} from "../ctx/MyContext";

export const ChatbotScreen = observer(() => {

    const ctx = useAppContext()
    const theme = useAppTheme()

    const showPanel = !theme.hideCollectionPicker || ctx.auth.store.isAdmin

    return <div css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
    `}>
        {showPanel && <DeveloperPanel />}
        <Chat />
    </div>
})
