import { Pivot, PivotItem } from '@fluentui/react'

// import styles from './AnalysisPanel.module.css'

import { AnalysisPanelTabs } from './AnalysisPanelTabs'
import { AskResponse, ChatbotThoughtProcessExplanation, DataPoint } from '@apis/my'
import { FacadeDocumentContent } from '../../document/FacadeDocumentContent'
import React, { ComponentType } from 'react'
import { css } from '@emotion/react'
import { TextWithWhiteSpace } from '../../ui/TextWithWhiteSpace'

interface Props {
    className: string
    activeTab: AnalysisPanelTabs
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void
    activeCitation: string | undefined
    citationHeight: string
    answer: AskResponse
}

const pivotItemDisabledStyle = { disabled: true, style: { color: 'grey' } }

export const AnalysisPanel = ({
                                  answer,
                                  activeTab,
                                  activeCitation,
                                  citationHeight,
                                  className,
                                  onActiveTabChanged,
                              }: Props) => {
    const isDisabledThoughtProcessTab: boolean = !answer.thoughts
    const isDisabledSupportingContentTab: boolean = !answer.dataPoints.length
    const isDisabledCitationTab: boolean = !activeCitation

    return (
        <Pivot
            className={className}
            selectedKey={activeTab}
            onLinkClick={(pivotItem) =>
                pivotItem &&
                onActiveTabChanged(
                    pivotItem.props.itemKey! as AnalysisPanelTabs,
                )
            }
        >
            <PivotItem
                itemKey={AnalysisPanelTabs.ThoughtProcessTab}
                headerText="Thought process"
                headerButtonProps={
                    isDisabledThoughtProcessTab
                        ? pivotItemDisabledStyle
                        : undefined
                }
            >
                <ThoughtProcess value={answer.thoughts} />
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.SupportingContentTab}
                headerText="Supporting content"
                headerButtonProps={
                    isDisabledSupportingContentTab
                        ? pivotItemDisabledStyle
                        : undefined
                }
            >
                <SupportingContent points={answer.dataPoints} />
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.CitationTab}
                headerText="Citation"
                headerButtonProps={
                    isDisabledCitationTab ? pivotItemDisabledStyle : undefined
                }
            >
                {activeCitation && (
                    <FacadeDocumentContent
                        title={'Citation'}
                        name={activeCitation}
                        height={citationHeight}
                    />
                )}
            </PivotItem>
        </Pivot>
    )
}

export const ThoughtProcess: ComponentType<{ value: ChatbotThoughtProcessExplanation }> = ({ value }) => {
    const { answer, answerPrompt } = value
    return <div>
        <h3>Query prompt</h3>
        <TextWithWhiteSpace>{value.queryPrompt}</TextWithWhiteSpace>
        <h3>Query</h3>
        <TextWithWhiteSpace>
            {value.query}
        </TextWithWhiteSpace>
        {answerPrompt &&
            <>
                <h3>Answer prompt (conversation)</h3>
                {answerPrompt.map(((m, i) =>
                        <div key={i}>
                            <div css={css`
                                font-weight: bold;
                            `}>
                                {m.role}
                            </div>
                            <TextWithWhiteSpace>

                                {m.content}
                            </TextWithWhiteSpace>
                        </div>
                ))}
            </>
        }
        {answer && <>
            <h3>Answer (from assistant)</h3>
            <p>
                {value.answer}
            </p>
        </>}
    </div>
}

export const SupportingContent: ComponentType<{ points: DataPoint[] }> = ({
                                                                              points,
                                                                          }) => {
    return (
        <ul>
            {points.map((p) => {
                return (
                    <div>
                        <h4>
                            {p.name}
                        </h4>
                        <TextWithWhiteSpace>
                            {p.content}
                        </TextWithWhiteSpace>
                    </div>
                )
            })}
        </ul>
    )
}
