import React, { ComponentType, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useApi } from '../api/useApi'
import { useCollectionStore } from '../chat/SearchCollectionsStore'
import { useForm } from 'react-hook-form'
import { useOpState } from '../ui/useOpState'
import { css } from '@emotion/react'
import { FormTextInput } from '../ui/form/FormTextInput'
import { Button } from '@fluentui/react-components'
import { OpStatus } from '../ui/OpStatus'
import { Collection, PdfExtractorType, SearchBackendType, SearchCollectionInfo } from '@apis/my'
import { FormEnumRadioGroup } from '../ui/form/FormRadioGroup'

export const CollectionAdmin = observer(() => {
    const store = useCollectionStore()

    const { selected, items } = store

    const data = store.collectionInfo

    return <div>
        {selected && <div>
            <h2>Collection '{store.selected?.name}'</h2>
            <CollectionInfo value={selected} info={data} />
        </div>}
        {items && items.length === 0 &&
            <div>No collection yet</div>
        }

        <hr />
        <div css={css`
            max-width: 20em;
        `}>
            <CreateNew />
        </div>
    </div>
})

const CollectionInfo: ComponentType<{ value: Collection, info?: SearchCollectionInfo }> = ({ value, info }) => {

    const store = useCollectionStore()
    const op = useOpState()

    const api = useApi()

    const form = useForm<{
        searchBackend: SearchBackendType
        pdfExtractor: PdfExtractorType
    }>({
        values: {
            searchBackend: value.searchBackend,
            pdfExtractor: value.pdfExtractor,
        },
    })

    useEffect(() => {
        // TODO find better way
        form.reset({
            searchBackend: value.searchBackend,
            pdfExtractor: value.pdfExtractor
        })
    }, [value.id])

    return (
        <div>
            <form
                onSubmit={form.handleSubmit(async (d) => {
                    await op.handle(
                        api.search.updateCollection(value.id, {
                            searchBackend: d.searchBackend,
                            pdfExtractor: d.pdfExtractor
                        }),
                    )
                    store.fetch()
                })}
            >
                <FormEnumRadioGroup
                    enumObj={SearchBackendType}
                    form={form}
                    field={'searchBackend'}
                    label={'Search backend'}
                />
                <FormEnumRadioGroup
                    enumObj={PdfExtractorType}
                    form={form}
                    field={'pdfExtractor'}
                    label={'PDF extract'}
                />
                <Button type={'submit'} appearance={'primary'}>
                    Save
                </Button>
            </form>
            <div>
                <h3>Stats</h3>
                <div>Semantic entities count: {info?.semanticEntitiesCount}</div>
                <div>Documents count: {info?.documentsCount}</div>
            </div>

            <div>
                <Button
                    onClick={() => {
                        let msg = 'Are you sure?'
                        if (info?.semanticEntitiesCount)
                            msg += `\n ${info?.semanticEntitiesCount} uploaded entries will be removed.`

                        const confirm = window.confirm(msg)
                        if (confirm) {
                            op.handle(
                                store.delete(value.id),
                            )
                        }
                    }}
                >
                    Delete
                </Button>
            </div>
            <OpStatus op={op} />
        </div>
    )
}

const CreateNew: ComponentType = observer(({}) => {
    const api = useApi()
    const store = useCollectionStore()

    const form = useForm<{
        name: string
    }>({
        values: {
            name: '',
        },
    })

    const { handleSubmit } = form

    const op = useOpState()

    return (
        <div>
            <form
                css={css`
                    > * {
                        margin-top: 1em;
                    }
                `}
                onSubmit={handleSubmit(async (d) => {
                    await op.handle(
                        api.search.createCollection({
                            name: d.name,
                        }),
                    )

                    form.reset()
                    store.fetch()
                })}
            >
                <h2>Creation a new collection</h2>
                <FormTextInput
                    label={'Collection name'}
                    form={form}
                    field={'name'}
                />
                <Button type={'submit'} appearance={'primary'}>
                    Create
                </Button>
                <OpStatus op={op} />
            </form>
        </div>
    )
})
