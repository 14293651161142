import { useMemo } from 'react'
import { IconButton, Stack } from '@fluentui/react'
import DOMPurify from 'dompurify'

import styles from './Answer.module.css'

import { parseAnswerToHtml } from './AnswerParser'
import { AnswerIcon } from './AnswerIcon'
import { AskResponse } from '@apis/my'

interface Props {
    answer: AskResponse
    isSelected?: boolean
    onCitationClicked: (filePath: string) => void
    onThoughtProcessClicked: () => void
    onSupportingContentClicked: () => void
    onAnswerAgain?: () => void
}

export const Answer = ({
    answer,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onAnswerAgain
}: Props) => {
    const parsedAnswer = useMemo(
        () => parseAnswerToHtml(answer.answer, onCitationClicked),
        [answer],
    )

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml)

    return (
        <Stack
            className={`${styles.answerContainer} ${
                isSelected && styles.selected
            }`}
            verticalAlign="space-between"
        >
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <AnswerIcon />
                    <div>
                        {onAnswerAgain &&
                        <IconButton
                            style={{ color: 'black' }}
                            iconProps={{ iconName: 'Refresh' }}
                            title="Answer again"
                            ariaLabel="Answer again"
                            onClick={onAnswerAgain}
                        />}
                        <IconButton
                            style={{ color: 'black' }}
                            iconProps={{ iconName: 'Lightbulb' }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
                        <IconButton
                            style={{ color: 'black' }}
                            iconProps={{ iconName: 'ClipboardList' }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.dataPoints.length}
                        />
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <div
                    className={styles.answerText}
                    dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}
                ></div>
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>
                            Citations:
                        </span>
                        {parsedAnswer.citations.map((x, i) => {
                            return (
                                <a
                                    key={i}
                                    className={styles.citation}
                                    title={x}
                                    onClick={() => onCitationClicked(x)}
                                >
                                    {`${++i}. ${x}`}
                                </a>
                            )
                        })}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    )
}
