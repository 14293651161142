import { css } from '@emotion/react'
import React from 'react'
import { QueryPanel } from './QueryPanel'
import { DocumentsPanel } from './docs/DocumentsPanel'
import { Tabs } from '../ui/Tabs'
import { DocDetailViewKey, QueryViewKey, useSearchConsoleStore, ViewType } from './SearchConsoleStore'
import { observer } from 'mobx-react'
import { DocumentsDetail } from './docs/DocumentsDetail'


export const SearchConsole = () => {
    return (
        <div>
            <h1
                css={css`
                    text-align: center;
                    margin-bottom: 0em;
                `}
            >
                Search console
            </h1>
            <div
                css={css`
                    width: 100%;
                    display: flex;
                `}
            >
                <div
                    css={css`
                        margin-left: 2em;
                        min-height: 90vh;
                        padding-right: 2em;
                        flex: 1;
                    `}
                >
                    <ContentTabs />
                </div>
                <div
                    css={css`
                        min-width: 30em;
                        border-left: 1px solid gray;
                        padding-left: 2em;
                    `}
                >
                    <h2>Documents</h2>
                    <>
                        <DocumentsPanel />
                    </>
                </div>
            </div>
        </div>
    )
}

const ContentTabs = observer(() => {
    const store = useSearchConsoleStore()
    return (
        <Tabs
            selected={{
                val: store.view,
                set: (val) => {
                    store.view = val as ViewType
                },
            }}
            tabs={[
                {
                    label: 'Query',
                    key: QueryViewKey,
                    render: () => <QueryPanel />,
                },
                {
                    label: 'Source document details',
                    key: DocDetailViewKey,
                    render: () => <DocumentsDetail />,
                },
            ]}
        />
    )
})
