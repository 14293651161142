import * as React from 'react'
import { ComponentType } from 'react'
import { Tab, TabList } from '@fluentui/react-components'
import { DefinedState } from './state'

export const Tabs: ComponentType<{
    selected: DefinedState<string>
    tabs: {
        label: string
        key: string
        render: () => React.ReactNode
    }[]
}> = ({ tabs, selected }) => {
    const selectedTab = tabs.find((t) => t.key === selected.val)!
    return (
        <div>
            <TabList
                selectedValue={selected.val}
                onTabSelect={(_, d) => selected.set(d.value as string)}
            >
                {tabs.map((t) => {
                    return (
                        <Tab key={t.key} value={t.key}>
                            {t.label}
                        </Tab>
                    )
                })}
            </TabList>
            <div>{selectedTab.render()}</div>
        </div>
    )
}
