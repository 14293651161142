import { OpState } from './useOpState'
import React from 'react'
import { ErrorMsg } from './ErrorMsg'
import { Spinner } from './Spinner'

export function OpStatus<D>({
    op,
}: {
    op: OpState<D>
}) {
    return (
        <div>
            <Spinner shown={op.pending} />
            <ErrorMsg error={op.error} />
        </div>
    )
}
